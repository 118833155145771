import react, { useEffect } from "react";
import "./App.css";
// importing aos
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    // AOS.init({ delay: 500, duration: 400, once: true });
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, []);
  useEffect(() => {
    const selectHeader = document.querySelector("#header");
    if (selectHeader) {
      let headerOffset = selectHeader.offsetTop;
      let nextElement = selectHeader.nextElementSibling;

      const headerFixed = () => { 
        if (headerOffset - window.scrollY <= 0) {
          selectHeader.classList.add("sticked");
          if (nextElement) nextElement.classList.add("sticked-header-offset");
        } else {
          selectHeader.classList.remove("sticked");
          if (nextElement)
            nextElement.classList.remove("sticked-header-offset");
        }
      };
      window.addEventListener("load", headerFixed);
      document.addEventListener("scroll", headerFixed);
    }
  }, []);
  useEffect(() => {
    let navbarlinks = document.querySelectorAll("#navbar a");

    function navbarlinksActive() {
      navbarlinks.forEach((navbarlink) => {
        if (!navbarlink.hash) return;

        let section = document.querySelector(navbarlink.hash);
        if (!section) return;

        let position = window.scrollY + 200;

        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          navbarlink.classList.add("active");
        } else {
          navbarlink.classList.remove("active");
        }
      });
    }
    window.addEventListener("load", navbarlinksActive);
    document.addEventListener("scroll", navbarlinksActive);
  }, []);
  useEffect(() => {
    const scrollTop = document.querySelector(".scroll-top");
    if (scrollTop) {
      const togglescrollTop = function () {
        window.scrollY > 100
          ? scrollTop.classList.add("active")
          : scrollTop.classList.remove("active");
      };
      window.addEventListener("load", togglescrollTop);
      document.addEventListener("scroll", togglescrollTop);
      scrollTop.addEventListener(
        "click",
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      );
    }
  }, []);

  // const mobileNavShow = document.querySelector(".mobile-nav-show");
  // const mobileNavHide = document.querySelector(".mobile-nav-hide");

  // document.querySelectorAll(".mobile-nav-toggle").forEach((el) => {
  //   el.addEventListener("click", function (event) {
  //     event.preventDefault();
  //     mobileNavToogle();
  //   });
  // });

  const mobileNavToogle = () => {
    const mobileNavShow = document.querySelector(".mobile-nav-show");
    const mobileNavHide = document.querySelector(".mobile-nav-hide");
    // document.body.classList.add('modal-open')
    // document.querySelector('body').classList.toggle('mobile-nav-active');
    document.body.classList.toggle("mobile-nav-active");
    // document.body.className = "mobile-nav-active";
    mobileNavShow.classList.toggle("d-none");
    mobileNavHide.classList.toggle("d-none");
  };

  return (
    <div className="">
      <section id="topbar" class="topbar d-flex align-items-center">
        <div class="container d-flex justify-content-center justify-content-md-between">
          <div class="contact-info d-flex align-items-center">
            <i class="bi bi-envelope d-flex align-items-center">
              <a href="/cdn-cgi/l/email-protection#2c4f4342584d4f586c49544d415c4049024f4341">
                <span
                  class="__cf_email__"
                  data-cfemail="82e1edecf6e3e1f6c2e7fae3eff2eee7ace1edef"
                >
              info@mancunian-fencing.co.uk
                </span>
              </a>
            </i>
            <i class="bi bi-phone d-flex align-items-center ms-4">
              <span>0161 436 3327</span>
            </i>
          </div>
          <div class="social-links d-none d-md-flex align-items-center">
            <a href="#" class="twitter">
              <i class="bi bi-twitter"></i>
            </a>
            <a href="#" class="facebook">
              <i class="bi bi-facebook"></i>
            </a>
            <a href="#" class="instagram">
              <i class="bi bi-instagram"></i>
            </a>
            <a href="#" class="linkedin">
              <i class="bi bi-linkedin"></i>
            </a>
          </div>
        </div>
      </section>
      <header id="header" class="header d-flex align-items-center">
        <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
          <a href="index.html" class="logo d-flex align-items-center">
            {/* <!-- Uncomment the line below if you also wish to use an image logo --> */}
            {/* <!--  */}
            {/* <img src="assets/img/logo.png" alt=""/> */}
            {/* --> */}
            {/* <img src={require('./img/mainlogo-1.jpg')} width={300} height={50} alt="mainlogo"/> */}
            <img
              src={require("./img/mainlogo.png")}
              width={300}
              height={50}
              alt="mainlogo"
            />
            {/* <h1>
              Mancunian Fencing<span>.</span>
            </h1> */}
          </a>
          <nav id="navbar" class="navbar">
            <ul>
              <li onClick={() => mobileNavToogle()}>
                <a href="#hero">Home</a>
              </li>
              <li onClick={() => mobileNavToogle()}>
                <a href="#about">About Us </a>
              </li>
              {/* <li class="dropdown">
                <a href="#">
                  <span>Drop Down</span>{" "}
                  <i class="bi bi-chevron-down dropdown-indicator"></i>
                </a>
                <ul>
                  <li>
                    <a href="#">Paving Stones and Slabs</a>
                  </li>
                  <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i class="bi bi-chevron-down dropdown-indicator"></i></a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li>
                  <li>
                    <a href="#">Decorative Gravel and Aggregates</a>
                  </li>
                  <li>
                    <a href="#">Garden Edging and Borders</a>
                  </li>
                  <li>
                    <a href="#">Retaining Wall Blocks</a>
                  </li>
                </ul>
              </li> */}
              <li onClick={() => mobileNavToogle()}>
                <a href="#products">Products</a>
              </li>
              <li onClick={() => mobileNavToogle()}>
                <a href="#visitOurYard">Visit our Yard </a>
              </li>
              <li onClick={() => mobileNavToogle()}>
                <a href="#contact">Contact us </a>
              </li>
              {/* <li><a href="blog.html">Blog</a></li> */}

              {/* <li><a href="#contact">Contact</a></li> */}
            </ul>
          </nav>

          <i
            onClick={() => mobileNavToogle()}
            class="mobile-nav-toggle mobile-nav-show bi bi-list"
            style={{ color: "#008374" }}
          ></i>
          <i
            onClick={() => mobileNavToogle()}
            class="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"
          ></i>
        </div>
      </header>
      <section id="hero" class="hero">
        <div class="container position-relative">
          <div class="row gy-5" data-aos="fade-in">
            <div class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center text-center text-lg-start">
              <h2>
                Welcome to <span> Mancunian Fencing</span>
                <span style={{ color: "#f96f59" }}>.</span>
              </h2>
              <p>
                With over 20 years experience our highly skilled team is based
                in South Manchester and provides a service to the whole of the
                Greater Manchester and Cheshire area.
              </p>
              <div class="d-flex justify-content-center justify-content-lg-start">
                <a href="#about" class="btn-get-started">
                  Get Started
                </a>
                {/* <a
                  href="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                  class="glightbox btn-watch-video d-flex align-items-center"
                >
                  <i class="bi bi-play-circle"></i>
                  <span>Watch Video</span>
                </a> */}
              </div>
            </div>
            <div class="col-lg-6 order-1 order-lg-2">
              <img
                src={require("./img/4.jpg")}
                class="img-fluid"
                alt=""
                data-aos="zoom-out"
                data-aos-delay="100"
              />
            </div>
          </div>
        </div>
        <div class="icon-boxes position-relative mt-5"></div>
      </section>

      <main id="main">
        <section id="about" class="about">
          <div class="container" data-aos="fade-up">
            <div class="section-header">
              <h2>About Us</h2>
              <p>
                We supply fencing products to both trade and diy enthusiasts or
                you can choose to utilise our time served installers for
                expertly erected fencing at your home or business We offer
                planning advice for fencing, gates, decking and landscape design
              </p>
            </div>

            <div class="row gy-4">
              <div class="col-lg-6">
                <h3 className="text-center">
                  -- Proudly Serving Greater Manchester --
                </h3>
                <img
                  src={require("./img/1.jpg")}
                  class="img-fluid rounded-4 mb-4"
                  alt=""
                />
                <p>
                  We are a group of time served fence builders and erectors who
                  came together five years ago to form one fencing company that
                  would be able to service both residential and trade fencing
                  customers in the Greater Manchester and Cheshire area with
                  outstandingly good fence products and services - at a price
                  that's fair.
                </p>
                <p class="fst-italic">
                  We build and erect all fencing types and install the whole
                  range in the wider Manchester areas. We guarantee all our
                  fence panels, products and fencing services.
                </p>
              </div>
              <div class="col-lg-6">
                <div class="content ps-0 ps-lg-5">
                  {/* <ul>
                    <li>
                      <i class="bi bi-check-circle-fill"></i> Ullamco laboris
                      nisi ut aliquip ex ea commodo consequat.
                    </li>
                    <li>
                      <i class="bi bi-check-circle-fill"></i> Duis aute irure
                      dolor in reprehenderit in voluptate velit.
                    </li>
                    <li>
                      <i class="bi bi-check-circle-fill"></i> Ullamco laboris
                      nisi ut aliquip ex ea commodo consequat. Duis aute irure
                      dolor in reprehenderit in voluptate trideta storacalaperda
                      mastiro dolore eu fugiat nulla pariatur.
                    </li>
                  </ul> */}
                  <p>
                    You can now choose from a wide range of fencing products
                    including, all the usual wooden panels and concrete posts,
                    along with our bespoke service (simply tell us your
                    requirements). We cover the whole of the Greater Manchester
                    and Cheshire area with our services. We are based in South
                    Manchester and provide a quick, clean and efficient fence
                    building and erection service.
                  </p>
                  <p>
                    Mancunian Fencing works in partnership with a number of
                    suppliers all of which source responsibly the wood used in
                    the making of fence panels and posts. Concrete products are
                    made and sourced locally to reduce carbon footprints.
                    Mancunian Fencing also provides and installs wooden gates
                    and can match gates with fencing. We make gates from both
                    hardwood and softwood. When softwood is used we use redwood
                    - the innermost and strongest part of the trunk.
                  </p>
                  <p>
                    Mancunian Fencing is part of the GCL Network and as such is
                    monitored and accountable. All workmanship guaranteed.
                  </p>

                  <div class="position-relative mt-4">
                    <img
                      src={require("./img/2.jpg")}
                      class="img-fluid rounded-4"
                      alt=""
                    />
                    {/* <a
                      href="https://www.youtube.com/watch?v=LXb3EKWsInQ"
                      class="glightbox play-btn"
                    ></a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section id="clients" class="clients">
          <div class="container" data-aos="zoom-out">
            <div class="clients-slider swiper">
              <div class="swiper-wrapper align-items-center">
                <div class="swiper-slide">
                  <img
                    src="assets/img/clients/client-1.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="assets/img/clients/client-2.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="assets/img/clients/client-3.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="assets/img/clients/client-4.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="assets/img/clients/client-5.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="assets/img/clients/client-6.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="assets/img/clients/client-7.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    src="assets/img/clients/client-8.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section id="stats-counter" class="stats-counter">
          <div class="container" data-aos="fade-up">
            <div class="row gy-4 align-items-center">
              <div class="col-lg-6">
                <img src="assets/img/stats-img.svg" alt="" class="img-fluid" />
              </div>

              <div class="col-lg-6">
                <div class="stats-item d-flex align-items-center">
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="232"
                    data-purecounter-duration="1"
                    class="purecounter"
                  ></span>
                  <p>
                    <strong>Happy Clients</strong> consequuntur quae diredo para
                    mesta
                  </p>
                </div>
             

                <div class="stats-item d-flex align-items-center">
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="521"
                    data-purecounter-duration="1"
                    class="purecounter"
                  ></span>
                  <p>
                    <strong>Projects</strong> adipisci atque cum quia aut
                  </p>
                </div>
              

                <div class="stats-item d-flex align-items-center">
                  <span
                    data-purecounter-start="0"
                    data-purecounter-end="453"
                    data-purecounter-duration="1"
                    class="purecounter"
                  ></span>
                  <p>
                    <strong>Hours Of Support</strong> aut commodi quaerat
                  </p>
                </div>
              
              </div>
            </div>
          </div>
        </section> */}

        {/* <section id="call-to-action" class="call-to-action">
          <div class="container text-center" data-aos="zoom-out">
            <a
              href="https://www.youtube.com/watch?v=LXb3EKWsInQ"
              class="glightbox play-btn"
            ></a>
            <h3>Call To Action</h3>
            <p>
              {" "}
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum.
            </p>
            <a class="cta-btn" href="#">
              Call To Action
            </a>
          </div>
        </section> */}
        {/* <section id="portfolio" class="portfolio sections-bg">
          <div class="container" data-aos="fade-up">
            <div class="section-header">
              <h2>Image Gallery</h2>
              <p>Take a look at some of our previous work</p>
            </div>

            <div
              class="portfolio-isotope"
              data-portfolio-filter="*"
              data-portfolio-layout="masonry"
              data-portfolio-sort="original-order"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="row gy-4 portfolio-container">
                <div class="col-xl-6 col-md-6 portfolio-item filter-app">
                  <div class="portfolio-wrap">
                    <img
                      src={require("./img/1.jpg")}
                      class="img-fluid"
                      alt=""
                      style={{
                        // objectFit: "fill",
                        height: "100%",
                        // height: "15rem",
                        // width: "100%",
                      }}

                    />
                  </div>
                </div>

                <div class="col-xl-6 col-md-6 portfolio-item filter-app">
                  <div class="portfolio-wrap">
                    <img
                      src={require("./img/2.jpg")}
                      class="img-fluid"
                      alt=""
                      style={{
                        // objectFit: "fill",
                        height: "100%",
                        // height: "15rem",
                        // width: "100%",
                      }}
                    />
                  </div>
                </div>
                <div class="col-xl-4 col-md-6 portfolio-item filter-app">
                  <div class="portfolio-wrap">
                    <img
                      src={require("./img/3.jpg")}
                      class="img-fluid"
                      alt=""
                      style={{
                        objectFit: "fill",
                        height: "17rem",
                        width: "100%",
                      }}
                    />
                  </div>
                </div>
                <div class="col-xl-4 col-md-6 portfolio-item filter-branding">
                  <div class="portfolio-wrap">
                    <img
                      src={require("./img/4.jpg")}
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
                <div class="col-xl-4 col-md-6 portfolio-item filter-branding">
                  <div class="portfolio-wrap">
                    <img
                      src={require("./img/5.jpg")}
                      class="img-fluid"
                      alt=""
                      style={{
                        // objectFit: "contain",
                        height: "100%",
                        // width: "100%",
                      }}
                    />
                  </div>
                </div>
              </div>
          
            </div>
          </div>
        </section> */}
        <section id="products" class="portfolio sections-bg">
          <div class="container" data-aos="fade-up">
            <div class="section-header">
              <h2>Our Products</h2>
              <p>
                {" "}
                At Mancunian Fencing, we pride ourselves on being your one-stop
                destination for all your fencing needs. Our extensive inventory
                includes a wide range of fencing products, ensuring that you
                find the perfect solution for your project. Whether you're a
                homeowner, contractor, or landscaper, we have everything you
                need to enhance the beauty, security, and privacy of your
                property.
              </p>
            </div>

            <div
              class="portfolio-isotope"
              data-portfolio-filter="*"
              data-portfolio-layout="masonry"
              data-portfolio-sort="original-order"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="row gy-4 portfolio-container">
                <div class="col-xl-4 col-md-6 portfolio-item filter-app">
                  <div class="portfolio-wrap">
                    <a
                      href="assets/img/portfolio/app-1.jpg"
                      data-gallery="portfolio-gallery-app"
                      class="glightbox"
                    >
                      <img
                        src={require("./img/waney-lap.jpg")}
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                    <div class="portfolio-info">
                      <h4>
                        <a href="portfolio-details.html" title="More Details">
                          Waney Lap Fencing
                        </a>
                      </h4>
                      <p>
                        {" "}
                        Offering a classic and cost-effective fencing solution,
                        Waney Lap fencing panels provide privacy and security
                        while adding aesthetic appeal to your outdoor space.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 portfolio-item filter-product">
                  <div class="portfolio-wrap">
                    <a
                      href="assets/img/portfolio/product-1.jpg"
                      data-gallery="portfolio-gallery-app"
                      class="glightbox"
                    >
                      <img
                        src={require("./img/5.jpg")}
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                    <div class="portfolio-info">
                      <h4>
                        <a href="portfolio-details.html" title="More Details">
                          Vertical Board Fencing
                        </a>
                      </h4>
                      <p>
                        {" "}
                        Known for its durability and contemporary look, vertical
                        board fencing is a popular choice for those seeking a
                        sleek and modern fence design.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 portfolio-item filter-branding">
                  <div class="portfolio-wrap">
                    <a
                      href="assets/img/portfolio/branding-1.jpg"
                      data-gallery="portfolio-gallery-app"
                      class="glightbox"
                    >
                      <img
                        src={require("./img/3.jpg")}
                        class="img-fluid"
                        alt=""
                        style={{
                          objectFit: "fill",
                          height: "15rem",
                          width: "100%",
                        }}
                      />
                    </a>
                    <div class="portfolio-info">
                      <h4>
                        <a href="portfolio-details.html" title="More Details">
                          Close Board Fencing
                        </a>
                      </h4>
                      <p>
                        Renowned for its strength and resilience, close board
                        fencing is ideal for properties requiring robust
                        security and privacy.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 portfolio-item filter-books">
                  <div class="portfolio-wrap">
                    <a
                      href="assets/img/portfolio/books-1.jpg"
                      data-gallery="portfolio-gallery-app"
                      class="glightbox"
                    >
                      <img
                        src={require("./img/4.jpg")}
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                    <div class="portfolio-info">
                      <h4>
                        <a href="portfolio-details.html" title="More Details">
                          Tanalised Fence Panels
                        </a>
                      </h4>
                      <p>
                        Our tanalised fence panels are pressure-treated for
                        long-lasting protection against rot and decay, ensuring
                        years of low-maintenance beauty for your fencing
                        project.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 portfolio-item filter-app">
                  <div class="portfolio-wrap">
                    <a
                      href="assets/img/portfolio/app-2.jpg"
                      data-gallery="portfolio-gallery-app"
                      class="glightbox"
                    >
                      <img
                        src={require("./img/concrete-fence-post.jpg")}
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                    <div class="portfolio-info">
                      <h4>
                        <a href="portfolio-details.html" title="More Details">
                          Wooden and Concrete Fence Posts
                        </a>
                      </h4>
                      <p>
                        We offer a variety of wooden and concrete fence posts
                        and bases to provide sturdy support for your fencing
                        panels, guaranteeing stability and longevity.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 portfolio-item filter-product">
                  <div class="portfolio-wrap">
                    <a
                      href="assets/img/portfolio/product-2.jpg"
                      data-gallery="portfolio-gallery-app"
                      class="glightbox"
                    >
                      <img
                        src={require("./img/9.jpg")}
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                    <div class="portfolio-info">
                      <h4>
                        <a href="portfolio-details.html" title="More Details">
                          Bespoke Fence Panels
                        </a>
                      </h4>
                      <p>
                        For unique or irregular spaces, our bespoke fence panels
                        can be customized to fit your specific requirements,
                        ensuring a perfect fit and seamless integration with
                        your property.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 portfolio-item filter-branding">
                  <div class="portfolio-wrap">
                    <a
                      href="assets/img/portfolio/branding-2.jpg"
                      data-gallery="portfolio-gallery-app"
                      class="glightbox"
                    >
                      <img
                        src={require("./img/Matching-Gates.jpg")}
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                    <div class="portfolio-info">
                      <h4>
                        <a href="portfolio-details.html" title="More Details">
                          Matching Gates
                        </a>
                      </h4>
                      <p>
                        {" "}
                        Complement your fencing with our matching gates,
                        available in various styles and sizes to suit your
                        aesthetic preferences and functional needs.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 portfolio-item filter-books">
                  <div class="portfolio-wrap">
                    <a
                      href="assets/img/portfolio/books-2.jpg"
                      data-gallery="portfolio-gallery-app"
                      class="glightbox"
                    >
                      <img
                        src={require("./img/8.jpg")}
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                    <div class="portfolio-info">
                      <h4>
                        <a href="portfolio-details.html" title="More Details">
                          All Common Fencing Solutions
                        </a>
                      </h4>
                      <p>
                        From classic picket fences to contemporary horizontal
                        slat designs, we stock all common fencing solutions to
                        cater to diverse tastes and preferences.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 portfolio-item filter-app">
                  <div class="portfolio-wrap">
                    <a
                      href="assets/img/portfolio/app-3.jpg"
                      data-gallery="portfolio-gallery-app"
                      class="glightbox"
                    >
                      <img
                        src={require("./img/10.jpg")}
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                    <div class="portfolio-info">
                      <h4>
                        <a href="portfolio-details.html" title="More Details">
                          Traditional Fencing Solutions
                        </a>
                      </h4>
                      <p>
                        Explore our selection of traditional fencing options,
                        including post and rail, feather edge, and more, for a
                        timeless and elegant look for your property.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 portfolio-item filter-product">
                  <div class="portfolio-wrap">
                    <img
                      src={require("./img/6.jpg")}
                      class="img-fluid"
                      alt=""
                    />

                    <div class="portfolio-info">
                      <h4>
                        <a href="portfolio-details.html" title="More Details">
                          Additional Services
                        </a>
                      </h4>
                      <p>
                        In addition to fencing products, we also offer solutions
                        for walls, garages, brickwork, and stonework, providing
                        comprehensive support for your outdoor construction
                        projects.
                      </p>
                    </div>
                  </div>
                </div>

                {/* <div class="col-xl-4 col-md-6 portfolio-item filter-branding">
                  <div class="portfolio-wrap">
                    <a
                      href="assets/img/portfolio/branding-3.jpg"
                      data-gallery="portfolio-gallery-app"
                      class="glightbox"
                    >
                      <img
                        src="assets/img/portfolio/branding-3.jpg"
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                    <div class="portfolio-info">
                      <h4>
                        <a href="portfolio-details.html" title="More Details">
                          Branding 3
                        </a>
                      </h4>
                      <p>Lorem ipsum, dolor sit amet consectetur</p>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-md-6 portfolio-item filter-books">
                  <div class="portfolio-wrap">
                    <a
                      href="assets/img/portfolio/books-3.jpg"
                      data-gallery="portfolio-gallery-app"
                      class="glightbox"
                    >
                      <img
                        src="assets/img/portfolio/books-3.jpg"
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                    <div class="portfolio-info">
                      <h4>
                        <a href="portfolio-details.html" title="More Details">
                          Books 3
                        </a>
                      </h4>
                      <p>Lorem ipsum, dolor sit amet consectetur</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>

        <section id="visitOurYard" class="team">
          <div class="container-fluid" data-aos="fade-up">
            <div class="section-header">
              <h2>Visit Our Yard</h2>
              <h5>Mancunian Fencing - Garlick Street - Manchester - M18 8UB</h5>
            </div>

            <div class="row ">
              <div
                class="col-xl-12 col-md-6 d-flex"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                {/* <iframe
                  style={{ width: "100%", height: "400px" }}
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
                  frameborder="0"
                  allowfullscreen
                ></iframe> */}
                {/* <iframe
                  style={{ width: "100%", height: "400px" }}
                  // width="520"
                  // height="400"
                  frameborder="0"
                  // scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Debdale%20Manchester+(Mancunian%20Fencing)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe> */}
                <iframe
                  style={{ width: "100%", height: "400px" }}
                  frameborder="0"
                  marginheight="0"
                  marginwidth="0"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?width=524&amp;height=397&amp;hl=en&amp;q=Garlick%20Street%20%20Manchester%20%20M18%208UB%20Manchester+(Mancunian%20Fencing)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        <section id="contact" class="contact">
          <div class="container" data-aos="fade-up">
            <div class="section-header">
              <h2>Contact Us</h2>
              {/* <p>
                Nulla dolorum nulla nesciunt rerum facere sed ut inventore quam
                porro nihil id ratione ea sunt quis dolorem dolore earum
              </p> */}
            </div>

            <div class="row gx-lg-0 gy-4">
              <div class="col-lg-12">
                <div class="info-container d-flex flex-column align-items-center justify-content-center">
                  <div class="info-item d-flex">
                    <i class="bi bi-geo-alt flex-shrink-0"></i>
                    <div>
                      <h4>Location:</h4>
                      <p>Garlick Street , Manchester, M18 8UB</p>
                    </div>
                  </div>
                  {/* <!-- End Info Item --> */}

                  <div class="info-item d-flex">
                    <i class="bi bi-envelope flex-shrink-0"></i>
                    <div>
                      <h4>Email:</h4>
                      <p>
                        {/* <a
                          href="/cdn-cgi/l/email-protection"
                          class="__cf_email__"
                          data-cfemail="11787f777e517469707c617d743f727e7c"
                        > */}
                        mancunian-fencing@hotmail.co.uk
                        {/* </a> */}
                      </p>
                    </div>
                  </div>
                  {/* <!-- End Info Item --> */}

                  <div class="info-item d-flex">
                    <i class="bi bi-phone flex-shrink-0"></i>
                    <div>
                      <h4>Call:</h4>
                      <p>07784 859 655</p>
                      <p>0161 436 3327</p>
                    </div>
                  </div>
                  {/* <!-- End Info Item --> */}

                  {/* <div class="info-item d-flex">
                    <i class="bi bi-clock flex-shrink-0"></i>
                    <div>
                      <h4>Open Hours:</h4>
                      <p>Mon-Sat: 11AM - 23PM</p>
                    </div>
                  </div> */}
                  {/* <div class="info-item d-flex">
                    <iframe
                      // style={{ width: "100%", height: "400px" }}
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </div> */}
                  {/* <!-- End Info Item --> */}
                </div>
              </div>

              {/* <div class="col-lg-8">
                <form
                  action="forms/contact.php"
                  method="post"
                  role="form"
                  class="php-email-form"
                >
                  <div class="row">
                    <div class="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        class="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div class="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>
                  <div class="form-group mt-3">
                    <input
                      type="text"
                      class="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div class="form-group mt-3">
                    <textarea
                      class="form-control"
                      name="message"
                      rows="7"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div class="my-3">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div class="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div> */}
              {/* <!-- End Contact Form --> */}
            </div>
          </div>
        </section>
      </main>
      <footer id="footer" class="footer">
        <div class="container">
          <div class="row gy-4">
            <div class="col-lg-12 col-md-12 footer-info">
              <a
                href="/"
                class="logo d-flex align-items-center justify-content-center"
              >
                <span className=""> Mancunian Fencing</span>
                <span style={{ color: "#f96f59" }}>.</span>
              </a>
              <p
                className="text-center"
                //  style={{paddingLeft:'10rem', paddingRight: '10rem'}}
              >
                With over 20 years experience our highly skilled team is based
                in South Manchester and provides a service to the whole of the
                Greater Manchester and Cheshire area.
                <br />
                We supply fencing products to both trade and diy enthusiasts or
                you can choose to utilise our time served installers for
                expertly erected fencing at your home or business
              </p>
              <h6 className="text-center mt-3 mb-4">
                Call or email for a free quotation for fencing suplies, fence
                erecting services and landscape gardening: Mobile: 07784 859655
              </h6>
              <div class="social-links d-flex mt-2 justify-content-center">
                <a href="#" class="twitter">
                  <i class="bi bi-twitter"></i>
                </a>
                <a href="#" class="facebook">
                  <i class="bi bi-facebook"></i>
                </a>
                <a href="#" class="instagram">
                  <i class="bi bi-instagram"></i>
                </a>
                <a href="#" class="linkedin">
                  <i class="bi bi-linkedin"></i>
                </a>
              </div>
            </div>

            {/* <div class="col-lg-2 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#">About us</a>
                </li>
                <li>
                  <a href="#">Services</a>
                </li>
                <li>
                  <a href="#">Terms of service</a>
                </li>
                <li>
                  <a href="#">Privacy policy</a>
                </li>
              </ul>
            </div> */}

            {/* <div class="col-lg-2 col-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <a href="#">Web Design</a>
                </li>
                <li>
                  <a href="#">Web Development</a>
                </li>
                <li>
                  <a href="#">Product Management</a>
                </li>
                <li>
                  <a href="#">Marketing</a>
                </li>
                <li>
                  <a href="#">Graphic Design</a>
                </li>
              </ul>
            </div> */}

            {/* <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>Contact Us</h4>
              <p>
                A108 Adam Street <br />
                New York, NY 535022
                <br />
                United States <br />
                <br />
                <strong>Phone:</strong> +1 5589 55488 55
                <br />
                <strong>Email:</strong>{" "}
                <a
                  href="/cdn-cgi/l/email-protection"
                  class="__cf_email__"
                  data-cfemail="8be2e5ede4cbeef3eae6fbe7eea5e8e4e6"
                >
                  [email&#160;protected]
                </a>
                <br />
              </p>
            </div> */}
          </div>
        </div>

        <div class="container mt-4" style={{ paddingTop: "1.5rem" }}>
          <div class="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>
                <span> Mancunian Fencing</span>
                <span style={{ color: "#f96f59" }}>.</span>{" "}
              </span>
            </strong>
            . All Rights Reserved
          </div>
          <div class="credits">
            {/* <!-- All the links in the footer should remain intact. -->
    <!-- You can delete the links only if you purchased the pro version. -->
    <!-- Licensing information: https://bootstrapmade.com/license/ -->
    <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/impact-bootstrap-business-website-template/ --> */}
            Designed & Developed by{" "}
            <a href="https://eight-1-eight.web.app/">Eight-1-Eight</a>
          </div>
        </div>
      </footer>
      {/* <!-- End Footer --> */}
      {/* <!-- End Footer --> */}

      <a
        href="#"
        class="scroll-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>

      {/* <div id="preloader"></div> */}
    </div>
  );
};

export default App;

// <section id="products" class="services sections-bg">
// <div class="container" data-aos="fade-up">
//   <div class="section-header">
//     <h2>Our Products</h2>
//     <p>
//       At Mancunian Fencing, we pride ourselves on being your one-stop
//       destination for all your fencing needs. Our extensive inventory
//       includes a wide range of fencing products, ensuring that you
//       find the perfect solution for your project. Whether you're a
//       homeowner, contractor, or landscaper, we have everything you
//       need to enhance the beauty, security, and privacy of your
//       property.
//     </p>
//   </div>

//   <div class="row gy-4" data-aos="fade-up" data-aos-delay="100">
//     <div class="col-lg-4 col-md-6">
//       <div class="service-item  position-relative">
//         <div class="icon">
//           <i class="bi bi-activity"></i>
//         </div>
//         <h3>Waney Lap Fencing</h3>
//         <p>
//           Offering a classic and cost-effective fencing solution,
//           Waney Lap fencing panels provide privacy and security while
//           adding aesthetic appeal to your outdoor space.
//         </p>
//         {/* <a href="#" class="readmore stretched-link">
//           Read more <i class="bi bi-arrow-right"></i>
//         </a> */}
//       </div>
//     </div>

//     <div class="col-lg-4 col-md-6">
//       <div class="service-item position-relative">
//         <div class="icon">
//           <i class="bi bi-broadcast"></i>
//         </div>
//         <h3>Vertical Board Fencing</h3>
//         <p>
//           Known for its durability and contemporary look, vertical
//           board fencing is a popular choice for those seeking a sleek
//           and modern fence design.
//         </p>
//         {/* <a href="#" class="readmore stretched-link">
//           Read more <i class="bi bi-arrow-right"></i>
//         </a> */}
//       </div>
//     </div>

//     <div class="col-lg-4 col-md-6">
//       <div class="service-item position-relative">
//         <div class="icon">
//           <i class="bi bi-easel"></i>
//         </div>
//         <h3>Close Board Fencing</h3>
//         <p>
//           Renowned for its strength and resilience, close board
//           fencing is ideal for properties requiring robust security
//           and privacy.
//         </p>
//         {/* <a href="#" class="readmore stretched-link">
//           Read more <i class="bi bi-arrow-right"></i>
//         </a> */}
//       </div>
//     </div>

//     <div class="col-lg-4 col-md-6">
//       <div class="service-item position-relative">
//         <div class="icon">
//           <i class="bi bi-bounding-box-circles"></i>
//         </div>
//         <h3>Tanalised Fence Panels</h3>
//         <p>
//           Our tanalised fence panels are pressure-treated for
//           long-lasting protection against rot and decay, ensuring
//           years of low-maintenance beauty for your fencing project.
//         </p>
//         {/* <a href="#" class="readmore stretched-link">
//           Read more <i class="bi bi-arrow-right"></i>
//         </a> */}
//       </div>
//     </div>

//     <div class="col-lg-4 col-md-6">
//       <div class="service-item position-relative">
//         <div class="icon">
//           <i class="bi bi-calendar4-week"></i>
//         </div>
//         <h3>Wooden and Concrete Fence Posts</h3>
//         <p>
//           We offer a variety of wooden and concrete fence posts and
//           bases to provide sturdy support for your fencing panels,
//           guaranteeing stability and longevity.
//         </p>
//         {/* <a href="#" class="readmore stretched-link">
//           Read more <i class="bi bi-arrow-right"></i>
//         </a> */}
//       </div>
//     </div>

//     <div class="col-lg-4 col-md-6">
//       <div class="service-item position-relative">
//         <div class="icon">
//           <i class="bi bi-chat-square-text"></i>
//         </div>
//         <h3>Bespoke Fence Panels</h3>
//         <p>
//           For unique or irregular spaces, our bespoke fence panels can
//           be customized to fit your specific requirements, ensuring a
//           perfect fit and seamless integration with your property.
//         </p>
//         {/* <a href="#" class="readmore stretched-link">
//           Read more <i class="bi bi-arrow-right"></i>
//         </a> */}
//       </div>
//     </div>
//     <div class="col-lg-4 col-md-6">
//       <div class="service-item position-relative">
//         <div class="icon">
//           <i class="bi bi-chat-square-text"></i>
//         </div>
//         <h3> Matching Gates</h3>
//         <p>
//           Complement your fencing with our matching gates, available
//           in various styles and sizes to suit your aesthetic
//           preferences and functional needs.
//         </p>
//         {/* <a href="#" class="readmore stretched-link">
//           Read more <i class="bi bi-arrow-right"></i>
//         </a> */}
//       </div>
//     </div>
//     <div class="col-lg-4 col-md-6">
//       <div class="service-item position-relative">
//         <div class="icon">
//           <i class="bi bi-chat-square-text"></i>
//         </div>
//         <h3>All Common Fencing Solutions</h3>
//         <p>
//           From classic picket fences to contemporary horizontal slat
//           designs, we stock all common fencing solutions to cater to
//           diverse tastes and preferences.
//         </p>
//         {/* <a href="#" class="readmore stretched-link">
//           Read more <i class="bi bi-arrow-right"></i>
//         </a> */}
//       </div>
//     </div>
//     <div class="col-lg-4 col-md-6">
//       <div class="service-item position-relative">
//         <div class="icon">
//           <i class="bi bi-chat-square-text"></i>
//         </div>
//         <h3>Traditional Fencing Solutions</h3>
//         <p>
//           Explore our selection of traditional fencing options,
//           including post and rail, feather edge, and more, for a
//           timeless and elegant look for your property.
//         </p>
//         {/* <a href="#" class="readmore stretched-link">
//           Read more <i class="bi bi-arrow-right"></i>
//         </a> */}
//       </div>
//     </div>
//     {/* <div class="col-lg-4 col-md-6">
//       <div class="service-item position-relative">
//         <div class="icon">
//           <i class="bi bi-chat-square-text"></i>
//         </div>
//         <h3>Additional Services</h3>
//         <p>
//           In addition to fencing products, we also offer solutions for
//           walls, garages, brickwork, and stonework, providing
//           comprehensive support for your outdoor construction
//           projects.
//         </p>
//         <a href="#" class="readmore stretched-link">
//           Read more <i class="bi bi-arrow-right"></i>
//         </a>
//       </div>
//     </div> */}

//   </div>
// </div>
// </section>

// <div class="icon-boxes position-relative">
// <div class="container position-relative">
//   <div class="row gy-4 mt-5">
//     <div
//       class="col-xl-3 col-md-6"
//       data-aos="fade-up"
//       data-aos-delay="300"
//     >
//       <div class="icon-box">
//         <div class="icon">{/* <i class="bi bi-easel"></i> */}</div>
//         <h4 class="title">
//           <a href="" class="stretched-link">
//             Paving Stones and Slabs
//           </a>
//         </h4>
//       </div>
//     </div>
//     {/* <!--End Icon Box --> */}

//     <div
//       class="col-xl-3 col-md-6"
//       data-aos="fade-up"
//       data-aos-delay="600"
//     >
//       <div class="icon-box">
//         <div class="icon">{/* <i class="bi bi-gem"></i> */}</div>
//         <h4 class="title">
//           <a href="" class="stretched-link">
//             Decorative Gravel and Aggregates
//           </a>
//         </h4>
//       </div>
//     </div>
//     {/* <!--End Icon Box --> */}

//     <div
//       class="col-xl-3 col-md-6"
//       data-aos="fade-up"
//       data-aos-delay="900"
//     >
//       <div class="icon-box">
//         <div class="icon">{/* <i class="bi bi-geo-alt"></i> */}</div>
//         <h4 class="title">
//           <a href="" class="stretched-link">
//             Garden Edging and Borders
//           </a>
//         </h4>
//       </div>
//     </div>
//     {/* <!--End Icon Box --> */}

//     <div
//       class="col-xl-3 col-md-6"
//       data-aos="fade-up"
//       data-aos-delay="1200"
//     >
//       <div class="icon-box">
//         <div class="icon">{/* <i class="bi bi-command"></i> */}</div>
//         <h4 class="title">
//           <a href="" class="stretched-link">
//             Retaining Wall Blocks
//           </a>
//         </h4>
//       </div>
//     </div>
//     {/* <!--End Icon Box --> */}
//   </div>
// </div>
// </div>
